<template>
    <div class="main-wrapper">
        <div class="admin-top">
            <div class="top-left">
                <span class="left-title">企业用户管理</span>
            </div>
            <div class="top-right">
                <el-button type="primary" @click="addEnterprise()">创建企业用户</el-button>
            </div>
        </div>
        <el-table :data="enterpriseTable"  style="width: 100%; flex: 1" height="1%"  size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="name" label="名称" align="left"></el-table-column>
            <el-table-column prop="username" label="账号" align="center"></el-table-column>
            <el-table-column prop="head_portrait" label="头像" align="center">
                <template width="32" slot-scope="scope" class="avatar">
                    <img :src="scope.row.head_portrait?scope.row.head_portrait:require('../../assets/img/image/user_img.png')" />
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="260">
                <template slot-scope="scope">
                    <el-button size="small" @click="resetPassword(scope.row)">重置密码</el-button>
                    <el-button size="small" @click="editEnterprise(scope.row)">编辑</el-button>
                    <el-button size="small" @click="deleteEnterprise(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="enterprisPages.currentPageNum"
                :page-size="enterprisPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="enterprisPages.total"
                @current-change="enterprisCurrentChange">
        </el-pagination>
        <el-dialog :title="enterpriseTitleType" :visible.sync="dialogEnterprise" center width="30%" @close="resetForm()" @opened="openAddEnterprise"  :close-on-click-modal="false">
            <el-form :model="enterpriseForm" ref="enterpriseForm" :rules="rules" label-position="left" class="student-info">
                <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="enterpriseForm.name" ref="enterpriseInput" autocomplete="off" placeholder="请输入名称" style="width: 80%;" ></el-input>
                </el-form-item>
                <el-form-item label="账号" :label-width="formLabelWidth" prop="username">
                    <el-input v-model="enterpriseForm.username"  v-if="this.enterpriseTitleType==='创建企业用户'" autocomplete="off" placeholder="请输入账号" style="width: 80%;" ></el-input>
                    <el-input v-model="enterpriseForm.username"  v-if="this.enterpriseTitleType==='编辑企业用户'" disabled autocomplete="off" placeholder="请输入账号" style="width: 80%;" ></el-input>
                </el-form-item>
                <el-form-item v-if="this.enterpriseTitleType==='创建企业用户'" label="密码" :label-width="formLabelWidth" prop="password" >
                    <el-input v-model="enterpriseForm.password" type="password" autocomplete="off" placeholder="请输入密码" style="width: 80%;" ></el-input>
                </el-form-item>
                <el-form-item label="头像" :label-width="formLabelWidth" prop="head_portrait">
                    <template>
                        <input class="up-img" @change="changeFile" type="file" id="id_avatar" name="file" title/>
                        <el-button size="small" type="primary">{{enterpriseForm.image?'重新上传':'点击上传'}}</el-button>
                        <img :src="enterpriseForm.image" v-show="enterpriseForm.image" class="up-img-show" alt />
                    </template>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" type="primary" @click="addToForm('enterpriseForm')">确 定</el-button>
                <el-button size="medium" @click="dialogEnterprise = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "EnterpriseManage",
        data(){
            return{
                enterpriseTable:[],
                enterpriseTitleType:'',
                enterpriseForm:{
                    id:'',
                    name:'',
                    username:'',
                    password:'',
                    image:'',
                    imageFile:'',
                },
                dialogEnterprise:false,
                formLabelWidth: '80px',
                distinguishBtn: '',
                rules: {
                    name: [
                        { required: true, message: '请输入名称', trigger: 'blur' },
                        { min: 1, max: 12, message: '长度在 1 到12 个字符', trigger: 'blur' },
                    ],
                    username: [
                        { required: true, message: '请输入账号', trigger: 'blur' },
                        { min: 1, max: 12, message: '长度在 1 到12 个字符', trigger: 'blur' },
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在1到20个字符', trigger: 'blur' },
                    ],
                },
                //分页
                enterprisPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total:0
                },
            }
        },
        created() {
            this.getEnterpriseList();
        },
        methods:{
            addEnterprise(){
                this.dialogEnterprise = !this.dialogEnterprise;
                this.enterpriseTitleType = '创建企业用户';
                this.distinguishBtn = 'add';
            },
            editEnterprise(row){
                this.dialogEnterprise = !this.dialogEnterprise;
                this.enterpriseTitleType = '编辑企业用户';
                this.distinguishBtn = 'edit';
                this.enterpriseForm.id = row.id;
                this.enterpriseForm.name = row.name;
                this.enterpriseForm.password = row.password;
                this.enterpriseForm.username = row.username;
                this.enterpriseForm.image=row.head_portrait;
            },
            // 获取企业管理员列表
            getEnterpriseList() {
                let param = {
                    page: this.enterprisPages.currentPageNum,
                    pageSize: this.enterprisPages.eachPageNum,
                    paging: 1,
                    type:2
                };
                this.$http.axiosGetBy(this.$api.enterpriseAdmin, param, (res) => {
                    if (res.code === 200) {
                        this.enterpriseTable = res.data.data;
                        this.enterprisPages.total = res.data.total;
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //新增修改企业用户
            addOrEdit(imageUrl){
                let formData = new FormData();
                formData.append("name", this.enterpriseForm.name);
                formData.append("username", this.enterpriseForm.username);
                formData.append("password", this.enterpriseForm.password);
                formData.append("head_portrait", imageUrl);
                formData.append("type", 2);
                if (this.distinguishBtn === 'edit') {
                    formData.append("id", this.enterpriseForm.id);
                }
                // console.log(formData)
                this.$http.axiosPost(this.$api.enterpriseAdmin, formData, res => {
                        if (res.code === 200) {
                            this.$message({
                                type: "success",
                                message: res.message,
                                duration: 1000,
                                onClose: () => {
                                    this.dialogEnterprise = !this.dialogEnterprise;
                                    this.resetForm();
                                    this.getEnterpriseList();
                                }
                            });
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    },
                    err => {
                        console.log(err);
                    }
                );
            },
            //添加/保存企业用户
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let param = {
                            username: this.enterpriseForm.name,
                        }
                        if (this.distinguishBtn === 'edit') {
                            param.id = this.enterpriseForm.id
                        }
                        this.$http.axiosGetBy(this.$api.username, param, res => {
                            if (res.code === 200) {
                                if (this.enterpriseForm.imageFile) {
                                    let fileOb = new FormData();
                                    fileOb.append("file", this.enterpriseForm.imageFile);
                                    this.$http.axiosPost(this.$api.upload, fileOb, res => {
                                        // console.log(res)
                                        if (res.code === 200) {
                                            this.addOrEdit(res.data)
                                        } else {
                                            this.$message({
                                                type: "error",
                                                message: res.message,
                                                duration: 1000
                                            });
                                        }
                                    })
                                } else {
                                    this.addOrEdit(this.enterpriseForm.image)
                                }
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.message,
                                    duration: 1000
                                });

                            }
                        })

                    }
                });
            },
            // 重置密码
            resetPassword(row) {
                this.$confirm("重置密码,默认123456", "提示消息", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true
                }).then(() => {
                    this.$http.axiosPost(this.$api.resetEnterprisePwd, {id: row.id,type:2}, (res) => {
                        console.log(res,'989')
                            if (res.code === 200) {
                                this.$message.success(res.message);
                            } else {
                                this.$message.warning(res.message);
                            }
                        },
                        err => {
                            console.log(err);
                        }
                    );
                }).catch(() => {
                    this.$message.info("已取消重置");
                });
            },
            // 删除
            deleteEnterprise(row){
                this.$confirm('是否删除该企业用户，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$http.axiosPost(this.$api.deleteEnterprise, {id: row.id,type:2}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message);
                            this.getEnterpriseList()
                        } else {
                            this.$message.warning(res.message)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
            // 头像
            changeFile(e) {
                if (this.beforeAvatarUpload(e.target.files[0])) {
                    this.enterpriseForm.imageFile = e.target.files[0];
                    var fr = new FileReader();
                    fr.onloadend = (e) => {
                        this.enterpriseForm.image = e.target.result;
                    };
                    fr.readAsDataURL(this.enterpriseForm.imageFile);
                } else {
                    document.getElementById("id_avatar").value = "";
                }
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg";
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error("上传头像图片只能是 JPG 格式!");
                }
                if (!isLt2M) {
                    this.$message.error("上传头像图片大小不能超过 2MB!");
                }
                return isJPG && isLt2M;
            },
            //重置表单
            resetForm() {
                this.$refs.enterpriseForm.resetFields();
                for (let key in this.enterpriseForm) {
                    this.enterpriseForm[key] = "";
                }
            },
            //打开弹窗聚焦输入框
            openAddEnterprise() {
                this.$refs.enterpriseInput.focus();
            },
            //分页
            enterprisCurrentChange(val) {
                this.enterprisPages.currentPageNum = val;
                this.getEnterpriseList();
            },
        }
    }
</script>

<style scoped lang="scss">
    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }

    .up-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 80px;
        height: 32px;
        opacity: 0;
    }

    .up-img-show {
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #eeeeee;
        vertical-align: middle;
        margin-left: 10px;
    }
    ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
        background-color: transparent;
    }
    ::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
        padding: 12px 9px;
    }
</style>